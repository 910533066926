import { AppRouteNames } from '../../../app.routenames';
import { SVLRouteValue } from '@domain/shared/svl-route-value';

export interface AccessRouteNamesType {
    login: SVLRouteValue;
    firstPassword: SVLRouteValue;
    recoverPassword: SVLRouteValue;
}

export const AccessRouteNames: AccessRouteNamesType = {
    login: { local: 'login', global: AppRouteNames.access + '/login' },

    firstPassword: {
        local: 'first-password',
        global: AppRouteNames.access + '/first-password',
    },

    recoverPassword: {
        local: 'recover-password',
        global: AppRouteNames.access + '/recover-password',
    },
};
